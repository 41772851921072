import React from "react";
import * as Components from "../../Components";
import { Link } from "react-router-dom";

const Dashboard = () => {
    // Site Title...
    document.title = "Dashboard || Hazri Xpert";

    const income_chart_labels = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    const income_chart_data_points = ["", 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    const expense_chart_labels = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    const expense_chart_data_points = ["", 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    return (
        <>
            <div className="container-fluid">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <Components.PageHeader />

                        <div className="nk-block">
                            <div className="row g-gs">
                                <div className="col-sm-4 col-xxl-3">
                                    <Link to="#">
                                        <div className="card card-full custom_card">
                                            <div className="card-inner">
                                                <div className="user-avatar text-light mb-3">
                                                    <em className="icon ni ni-users" />
                                                </div>
                                                <h3 className="title mt-2">00</h3>
                                                <p className="sub-text">Active Parent!</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-sm-4 col-xxl-3">
                                    <Link to="#">
                                        <div className="card card-full custom_card">
                                            <div className="card-inner">
                                                <div className="user-avatar text-light mb-3">
                                                    <em className="icon ni ni-users" />
                                                </div>
                                                <h3 className="title mt-2">00</h3>
                                                <p className="sub-text">Inactive Parent!</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-sm-4 col-xxl-3">
                                    <Link to="#">
                                        <div className="card card-full custom_card">
                                            <div className="card-inner">
                                                <div className="user-avatar text-light mb-3">
                                                    <em className="icon ni ni-users" />
                                                </div>
                                                <h3 className="title mt-2">00</h3>
                                                <p className="sub-text">Active Teacher!</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-sm-4 col-xxl-3">
                                    <Link to="#">
                                        <div className="card card-full custom_card">
                                            <div className="card-inner">
                                                <div className="user-avatar text-light mb-3">
                                                    <em className="icon ni ni-users" />
                                                </div>
                                                <h3 className="title mt-2">00</h3>
                                                <p className="sub-text">Inactive Teacher!</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-sm-4 col-xxl-3">
                                    <Link to="#">
                                        <div className="card card-full custom_card">
                                            <div className="card-inner">
                                                <div className="user-avatar text-light mb-3">
                                                    <em className="icon ni ni-users" />
                                                </div>
                                                <h3 className="title mt-2">00</h3>
                                                <p className="sub-text">Active Student!</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-sm-4 col-xxl-3">
                                    <Link to="#">
                                        <div className="card card-full custom_card">
                                            <div className="card-inner">
                                                <div className="user-avatar text-light mb-3">
                                                    <em className="icon ni ni-users" />
                                                </div>
                                                <h3 className="title mt-2">00</h3>
                                                <p className="sub-text">Inactive Student!</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-12">
                                    <Components.DashboardChart
                                        title="Income"
                                        labels={income_chart_labels}
                                        data={income_chart_data_points}
                                    />
                                </div>

                                <div className="col-12">
                                    <Components.DashboardChart
                                        title="Expense"
                                        labels={expense_chart_labels}
                                        data={expense_chart_data_points}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
