import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Components from "../../Components/index";
import { useAuth } from "../../Contexts/AuthContext";

const PageHeader = (props) => {
	// Location...
	const location = useLocation();

	const current_path_name = location.pathname;

	const navigate = useNavigate();

	const { user } = useAuth();


	return (
		<>
			{current_path_name.split("/")[2] === "dashboard" ? (
				<div className="nk-block-head">
					<div className="nk-block-head-sub">
						<span>Welcome!</span>
					</div>
					<div className="nk-block-between-md g-4">
						<div className="nk-block-head-content">
							<h2 className="nk-block-title fw-normal">
								{user.owner_full_name}
							</h2>
						</div>
					</div>
				</div>
			) : (
				<div className="nk-block-head nk-block-head-sm card shadow-sm card-stretch px-3 py-4 mb-3">
					<div className="nk-block-between">
						<div className="nk-block-head-content">
							<h3 className="nk-block-title page-title text-secondary">
								{props.headingText}
							</h3>
						</div>

						{props.buttonText && (
							<div className="nk-block-head-content">
								<div className="toggle-wrap nk-block-tools-toggle">
									<Components.Button
										onClick={() => navigate(props.buttonLink)}
										className="btn btn_primary"
										icon={props.buttonIcon}
										label={props.buttonText}
									/>

								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default PageHeader;
