import { createBrowserRouter } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import * as Layouts from "../Layouts";
import * as Pages from "../Pages";

const Routes = [
    {
        path: "/",
        element: <ProtectedRoutes allowedRoles={["super_admin", "admin"]} />,
        errorElement: <Pages.NotFound404 />,
        children: [
            {
                path: ":role/dashboard",
                element: <Layouts.MainLayout />,
                children: [
                    { path: "", element: <Pages.Dashboard /> },
                    { path: "*", element: <Pages.NotFound404 /> },
                ],
            },
            {
                path: ":role/programs",
                element: <Layouts.MainLayout />,
                children: [
                    { path: "", element: <Pages.Programs /> },
                    { path: "create", element: <Pages.CreateProgram /> },
                    { path: ":id/edit", element: <Pages.EditProgram /> },
                    { path: "*", element: <Pages.NotFound404 /> },
                ],
            },
            {
                path: ":role/enrollments",
                element: <Layouts.MainLayout />,
                children: [
                    {
                        path: "parents",
                        element: <Pages.ParentsList />,
                    },
                    {
                        path: "create",
                        element: <Pages.CreateEnrollment />,
                    },
                    { path: "*", element: <Pages.NotFound404 /> },
                ],
            },
        ],
    },
    {
        path: "login",
        element: <Layouts.AuthLayout />,
        children: [
            { path: "", element: <Pages.Login /> },
            { path: "*", element: <Pages.NotFound404 /> },
        ],
    },
    {
        path: "*",
        element: <Pages.NotFound404 />,
    },
];

const router = createBrowserRouter(Routes);

export default router;
