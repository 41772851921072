import React, { useState } from "react";
import * as Components from "../../Components";
import DummyImage from "../../Assets/backend/images/dummey_profile.jpeg"
import Data from "../../Data/index.json"

const ParentOrGuardianForm = (props) => {
    const [type, setType] = useState("1");
    const [fields, setFormFields] = useState({
        type: '',
        relation: '',
        full_name: '',
        email_address: '',
        contact_no: '',
        password: '',
        cnic: '',
        occupation: '',
        gender: '',
        image: '',
        about_information: '',
        address: '',

    });
    const [password_type, setPasswordType] = useState("password");
    const [image_preview, setImagePreview] = useState(DummyImage)
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const togglePassword = () => {
        setPasswordType(password_type === "password" ? "text" : "password");
    };

    const validateField = (name, value) => {
        let error = '';
        const full_name_pattern = /^[A-Za-z\s]{3,50}$/;
        const password_pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)\S{8,15}$/;
        const email_address_pattern =
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;

        switch (name) {
            case 'relation':
                if (!value && type === "3") {
                    error = "Relation is required.";
                }
                break;
            case 'full_name':
                if (!value) {
                    error = "Full name is required.";
                } else if (value && !full_name_pattern.test(value)) {
                    error = "Full name must be between 3 to 50 characters and contain only alphabets.";
                }
                break;
            case 'contact_no':
                if (!value) {
                    error = "Invalid contact number. Please ensure it includes the country code and follows the correct format.";
                }
                break;
            case 'password':
                if (!value) {
                    error = "Password is required.";
                } else if (value && !password_pattern.test(value)) {
                    error = "Password must contain at least one uppercase, lowercase, a number or a special character,  minimum 8 characters and maximum 15 charecters.";
                }
                break;
            case 'email_address':
                if (value && !email_address_pattern.test(value)) {
                    error = "Invalid email address, Only letters (a-z), numbers (0-9) and period (.) are allowed!";
                }
                break;
            default:
                break;
        }

        return error;
    };

    const validateFields = () => {
        const newErrors = {};

        Object.keys(fields).forEach((key) => {
            const error = validateField(key, fields[key]);
            if (error) {
                newErrors[key] = error;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        
        if (type === 'file') {
            const file = files[0];

            setFormFields((prev) => ({ ...prev, [name]: file }));

            if (file) {
                setImagePreview(URL.createObjectURL(file));
            } else {
                setImagePreview(DummyImage);
            }
        } else {
            setFormFields((prev) => ({ ...prev, [name]: value }));
        }

        if (name === 'type') {
            setType(value);
            setErrors({});
            setImagePreview(DummyImage);
            setFormFields({
                type: value,
                relation: '',
                full_name: '',
                email_address: '',
                contact_no: '',
                password: '',
                cnic: '',
                occupation: '',
                gender: '',
                image: '',
                about_information: '',
                address: '',
            });
            setErrors((prev) => ({
                ...prev,
                relation: value === '3' ? validateField('relation', fields.relation) : '',
            }));
        }

        const error = validateField(name, type === 'file' ? files[0] : value);
        setErrors((prev) => ({ ...prev, [name]: error }));
    };

    const handleContactNoChange = (value, country) => {
        const dialCodeLength = country.dialCode.length;
        const formatLength = country.format.replace(/ /g, "").length;

        const isValidLength = value.length + dialCodeLength === formatLength;

        setFormFields((prev) => ({
            ...prev,
            contact_no: value
        }));

        const error = isValidLength ? null : "Invalid contact number. Please ensure it includes the country code and follows the correct format.";
        setErrors((prev) => ({
            ...prev,
            contact_no: error
        }));
    };

    const onHandleNext = async () => {
        setIsLoading(true);
        if (!validateFields()) {
            setIsLoading(false);
            return;
        }

        try {
            setTimeout(() => {
                setIsLoading(false);
                return props.goToNextStep();
            }, 3000);
        } catch (error) {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="row mb-2">
                <div className="col-12 mt-3">
                    <Components.UnifiedInputField
                        inputType="radio"
                        label="Guardian Type"
                        type="radio"
                        name="type"
                        value={fields.type}
                        checked={type}
                        onChange={handleChange}
                        error={errors.type}
                        required={false}
                        options={Data.guardian_type}
                    />
                </div>

                {type === "1" && (
                    <>
                        <div className="col-md-4 col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="input"
                                label="Full Name"
                                type="text"
                                name="full_name"
                                value={fields.full_name}
                                onChange={handleChange}
                                error={errors.full_name}
                                placeholder="Enter Full Name"
                                required={true}
                            />
                        </div>

                        <div className="col-md-4 col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="phone"
                                label="Contact Number"
                                type="text"
                                name="contact_no"
                                value={fields.contact_no}
                                onChange={(value, country) => handleContactNoChange(value, country)}
                                placeholder="Enter Contact Number"
                                error={errors.contact_no}
                                className="phone_input"
                                country={"pk"}
                                enableSearch={true}
                                required={true}
                            />
                        </div>

                        <div className="col-md-4 col-12 mt-3">
                            <Components.UnifiedInputField
                                label="Password"
                                type="password"
                                name="password"
                                value={fields.password}
                                onChange={handleChange}
                                error={errors.password}
                                placeholder="Enter Password"
                                togglePassword={togglePassword}
                                password_type={password_type}
                                required
                            />
                        </div>

                        <div className="col-md-4 col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="input"
                                label="Email Address"
                                type="text"
                                name="email_address"
                                value={fields.email_address}
                                onChange={handleChange}
                                error={errors.email_address}
                                placeholder="Enter Email Address"
                                required={false}
                            />
                        </div>

                        <div className="col-md-4 col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="input"
                                label="CNIC"
                                type="number"
                                name="cnic"
                                value={fields.cnic}
                                onChange={handleChange}
                                error={errors.cnic}
                                placeholder="Enter CNIC"
                                required={false}
                            />
                        </div>

                        <div className="col-md-4 col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="input"
                                label="Occupation"
                                type="text"
                                name="occupation"
                                value={fields.occupation}
                                onChange={handleChange}
                                error={errors.occupation}
                                placeholder="Enter Occupation"
                                required={false}
                            />
                        </div>

                        <div className="col-md-11 col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="file"
                                label="Image"
                                type="file"
                                name="image"
                                value={fields.image}
                                onChange={handleChange}
                                error={errors.image}
                                placeholder="Upload Image"
                                required={false}
                                accept="image/*"
                            />
                        </div>

                        <div className="col-12 col-md-1 mt-4 text-center">
                            <img
                                src={image_preview}
                                alt="Father_Image"
                                className="rounded-circle"
                                height="80"
                                width="80"
                            />
                        </div>

                        <div className="col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="ckeditor"
                                label="About Information"
                                type="textarea"
                                name="about_information"
                                value={fields.about_information}
                                onChange={handleChange}
                                error={errors.about_information}
                                placeholder="Enter About Information"
                                required={false}
                            />
                        </div>

                        <div className="col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="ckeditor"
                                label="Address"
                                type="textarea"
                                name="address"
                                value={fields.address}
                                onChange={handleChange}
                                error={errors.address}
                                placeholder="Enter Address"
                                required={false}
                            />
                        </div>
                    </>
                )}

                {type === "2" && (
                    <>
                        <div className="col-md-4 col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="input"
                                label="Full Name"
                                type="text"
                                name="full_name"
                                value={fields.full_name}
                                onChange={handleChange}
                                error={errors.full_name}
                                placeholder="Enter Full Name"
                                required={true}
                            />
                        </div>

                        <div className="col-md-4 col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="phone"
                                label="Contact Number"
                                type="text"
                                name="contact_no"
                                value={fields.contact_no}
                                onChange={handleContactNoChange}
                                placeholder="Enter Contact Number"
                                error={errors.contact_no}
                                className="phone_input"
                                country={"pk"}
                                enableSearch={true}
                                required={true}
                            />
                        </div>

                        <div className="col-md-4 col-12 mt-3">
                            <Components.UnifiedInputField
                                label="Password"
                                type="password"
                                name="password"
                                value={fields.password}
                                onChange={handleChange}
                                error={errors.password}
                                placeholder="Enter Password"
                                togglePassword={togglePassword}
                                password_type={password_type}
                                required={true}
                            />
                        </div>

                        <div className="col-md-4 col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="input"
                                label="Email Address"
                                type="text"
                                name="email_address"
                                value={fields.email_address}
                                onChange={handleChange}
                                error={errors.email_address}
                                placeholder="Enter Email Address"
                                required={false}
                            />
                        </div>

                        <div className="col-md-4 col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="input"
                                label="CNIC"
                                type="number"
                                name="cnic"
                                value={fields.cnic}
                                onChange={handleChange}
                                error={errors.cnic}
                                placeholder="Enter CNIC"
                                required={false}
                            />
                        </div>

                        <div className="col-md-4 col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="input"
                                label="Occupation"
                                type="text"
                                name="occupation"
                                value={fields.occupation}
                                onChange={handleChange}
                                error={errors.occupation}
                                placeholder="Enter Occupation"
                                required={false}
                            />
                        </div>

                        <div className="col-md-11 col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="file"
                                label="Image"
                                type="file"
                                name="image"
                                value={fields.image}
                                onChange={handleChange}
                                error={errors.image}
                                placeholder="Upload Image"
                                required={false}
                                accept="image/*"
                            />
                        </div>

                        <div className="col-12 col-md-1 mt-4 text-center">
                            <img
                                src={image_preview}
                                alt="Mother_Image"
                                className="rounded-circle"
                                height="80"
                                width="80"
                            />
                        </div>

                        <div className="col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="ckeditor"
                                label="About Information"
                                type="textarea"
                                name="about_information"
                                value={fields.about_information}
                                onChange={handleChange}
                                error={errors.about_information}
                                placeholder="Enter About Information"
                                required={false}
                            />
                        </div>

                        <div className="col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="ckeditor"
                                label="Address"
                                type="textarea"
                                name="address"
                                value={fields.address}
                                onChange={handleChange}
                                error={errors.address}
                                placeholder="Enter Address"
                                required={false}
                            />
                        </div>
                    </>
                )}

                {type === "3" && (
                    <>
                        <div className="col-md-4 col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="input"
                                label="Relation"
                                type="text"
                                name="relation"
                                value={fields.relation}
                                onChange={handleChange}
                                error={errors.relation}
                                placeholder="Enter Relation"
                                required={true}
                            />
                        </div>

                        <div className="col-md-4 col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="input"
                                label="Full Name"
                                type="text"
                                name="full_name"
                                value={fields.full_name}
                                onChange={handleChange}
                                error={errors.full_name}
                                placeholder="Enter Full Name"
                                required={true}
                            />
                        </div>

                        <div className="col-md-4 col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="phone"
                                label="Contact Number"
                                type="text"
                                name="contact_no"
                                value={fields.contact_no}
                                onChange={handleContactNoChange}
                                placeholder="Enter Contact Number"
                                error={errors.contact_no}
                                className="phone_input"
                                country={"pk"}
                                enableSearch={true}
                                required={true}
                            />
                        </div>

                        <div className="col-md-4 col-12 mt-3">
                            <Components.UnifiedInputField
                                label="Password"
                                type="password"
                                name="password"
                                value={fields.password}
                                onChange={handleChange}
                                error={errors.password}
                                placeholder="Enter Password"
                                togglePassword={togglePassword}
                                password_type={password_type}
                                required={true}
                            />
                        </div>

                        <div className="col-md-4 col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="input"
                                label="Email Address"
                                type="text"
                                name="email_address"
                                value={fields.email_address}
                                onChange={handleChange}
                                error={errors.email_address}
                                placeholder="Enter Email Address"
                                required={false}
                            />
                        </div>

                        <div className="col-md-4 col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="input"
                                label="CNIC"
                                type="number"
                                name="cnic"
                                value={fields.cnic}
                                onChange={handleChange}
                                error={errors.cnic}
                                placeholder="Enter CNIC"
                                required={false}
                            />
                        </div>

                        <div className="col-md-4 col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="input"
                                label="Occupation"
                                type="text"
                                name="occupation"
                                value={fields.occupation}
                                onChange={handleChange}
                                error={errors.occupation}
                                placeholder="Enter Occupation"
                                required={false}
                            />
                        </div>

                        <div className="col-md-4 col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="select"
                                label="Gender"
                                type="select"
                                name="gender"
                                value={fields.gender}
                                onChange={handleChange}
                                error={errors.gender}
                                placeholder="Select Gender"
                                required={false}
                                options={Data.gender}
                            />
                        </div>

                        <div className="col-md-11 col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="file"
                                label="Image"
                                type="file"
                                name="image"
                                onChange={handleChange}
                                error={errors.image}
                                placeholder="Upload Image"
                                required={false}
                                accept="image/*"
                            />
                        </div>

                        <div className="col-12 col-md-1 mt-4 text-center">
                            <img
                                src={image_preview}
                                alt="Guardian_Image"
                                className="rounded-circle"
                                height="80"
                                width="80"
                            />
                        </div>

                        <div className="col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="ckeditor"
                                label="About information"
                                type="textarea"
                                name="about_information"
                                value={fields.about_information}
                                onChange={handleChange}
                                error={errors.about_information}
                                placeholder="Enter About information"
                                required={false}
                            />
                        </div>

                        <div className="col-12 mt-3">
                            <Components.UnifiedInputField
                                inputType="ckeditor"
                                label="Address"
                                type="textarea"
                                name="address"
                                value={fields.address}
                                onChange={handleChange}
                                error={errors.address}
                                placeholder="Enter Address"
                                required={false}
                            />
                        </div>
                    </>
                )}
            </div>

            <hr />
            <Components.Button
                isLoading={isLoading}
                onClick={onHandleNext}
                label="Next"
                className="btn btn_accent"
            />
        </>
    );
};

export default ParentOrGuardianForm;
