import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext";

const ProtectedRoute = ({ allowedRoles }) => {
	const { isAuth, role } = useAuth();
	const location = useLocation();

	if (!isAuth) {
		return <Navigate to="/login" replace />;
	}

	const pathRole = location.pathname.split("/")[1];

	if ((allowedRoles && !allowedRoles.includes(role)) || (pathRole !== role)) {
		return <Navigate to={`/${role}/dashboard`} replace />;
	}

	return <Outlet />;
};

export default ProtectedRoute;
