import React from "react";
import "../../Styles";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {
    return (
        <>
            {/* Main */}
            <div className="nk-app-root" id="main">
                <div className="nk-main">
                    <div className="nk-wrap justify-content-center">
                        <div className="nk-content">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AuthLayout;
