import React, { createContext, useContext, useState, useEffect } from "react";
import { isAuthenticated, getUserRole, getUserData, login, logout } from "../../Utils/AuthUtil";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuth, setIsAuth] = useState(isAuthenticated());
    const [role, setRole] = useState(getUserRole());
    const [user, setUser] = useState(getUserData());

    useEffect(() => {
        if (isAuth) {
            const userData = getUserData();
            setUser(userData);
            setRole(getUserRole());
        }
    }, [isAuth]);

    const loginUser = (userData) => {
        login(userData);
        setIsAuth(true);
        setRole(getUserRole());
        setUser(userData);
    };

    const logoutUser = () => {
        logout();
        setIsAuth(false);
        setRole(null);
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ isAuth, user, role, loginUser, logoutUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
