import React, { useEffect, useState } from "react";
import "../../Styles";
import { Footer, Header, SideBar } from "../../Components";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
    // States...
    const [is_header_menu_compact, setIsHeaderMenuCompact] = useState(false);

    // UseEffect...
    useEffect(() => {
        // Is Header Menu Compact...
        localStorage.setItem("is_header_menu_compact", is_header_menu_compact);
        if (is_header_menu_compact) {
            document
                .querySelector("body .nk-sidebar")
                .classList.add("nk-sidebar-active");
        } else {
            document
                .querySelector("body .nk-sidebar")
                .classList.remove("nk-sidebar-active");
        }
    }, [is_header_menu_compact]);

    return (
        <>
            <div className="nk-app-root" id="main">
                <div className="nk-main">
                    <div
                        className="nk-sidebar nk-sidebar-fixed is-dark"
                        data-content="sidebarMenu"
                    >
                        <SideBar setIsHeaderMenuCompact={setIsHeaderMenuCompact} />
                    </div>

                    <div className="nk-wrap">
                        <div className="nk-header nk-header-fixed is-light">
                            <Header setIsHeaderMenuCompact={setIsHeaderMenuCompact} />
                        </div>

                        <div className="nk-content">
                            <Outlet />
                        </div>

                        <div className="nk-footer">
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MainLayout;
