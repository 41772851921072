import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const DashboardChart = (props) => {
  const chart_data = {
    labels: props.labels,
    datasets: [
      {
        label: props.title,
        data: props.data,
        borderColor: "#c376a3",
        backgroundColor: "#e1cce6",
        pointBorderColor: "#c376a3",
        pointBackgroundColor: "#c376a3",
        pointBorderWidth: 1,
        fill: true,
      },
    ],
  };

  const chart_options = {
    scales: {
      x: {
        type: "category",
        display: true,
      },
      y: {
        type: "linear",
        display: true,
        min: -1.0,
        max: 1.0,
        ticks: {
          stepSize: 0.2,
        },
      },
    },
  };

  return (
    <>
      <div className="card card-full custom_card mt-3">
        <div className="card-inner">
          <h4 className="text-primary">{props.title}</h4>
          <Line className="mt-5" data={chart_data} options={chart_options} />
        </div>
      </div>
    </>
  );
};

export default DashboardChart;
