import React from "react";
import * as Components from "../../../Components";
import 'datatables.net';
import '../../../Assets/backend/assets/css/dataTable.css';
import { useAuth } from "../../../Contexts/AuthContext";

const Parents = () => {
    document.title = "Parents || Hazri Xpert";

    const { role } = useAuth();

    return (
        <>
            <div className="container-fluid">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <Components.PageHeader
                            headingText="Parents"
                            buttonText="Create New Enrollment"
                            buttonIcon="plus"
                            buttonLink={`/${role}/enrollments/create`}
                        />

                        <div className="nk-block">
                            <div className="card shadow-sm card-stretch">
                                <div className="card-inner">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Parents;
