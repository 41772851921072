import React from "react";
import { RouterProvider } from "react-router-dom";
import Route from "./Routes";
import { AuthProvider } from './Contexts/AuthContext'

const App = () => {
	return (
		<AuthProvider>
			<RouterProvider router={Route} />
		</AuthProvider>
	);
};

export default App;
