import React, { useState } from "react";
import * as Components from "../../Components";
// import * as API_CONSTANTS from "../../ApiConstants/index";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext";

const CreateProgram = () => {
    // Site Title...
    document.title = "Create New Program || Hazri Xpert";

    const [formFields, setFormData] = useState({
        name: "",
    });

    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [alertShow, setAlertShow] = useState(false);
    const [jsonMessage, setJsonMessage] = useState("");

    const navigate = useNavigate();

    const { role } = useAuth();

    const validateFields = () => {
        const newErrors = {};

        if (!formFields.name) {
            newErrors.name = "Program name is required.";
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const onHandleSubmit = async () => {
        setIsLoading(true);

        if (!validateFields()) {
            setIsLoading(false);
            return;
        }

        try {
            // const bodyParameters = new FormData();
            // bodyParameters.append("name", formFields.name);

            // const response = await axios({
            //     method: "POST",
            //     url: API_CONSTANTS.STORE_PROGRAM,
            //     headers: {
            //         "Authorization": `Bearer ${token}`,
            //         "Access-Control-Allow-Origin": "*",
            //     },
            //     data: bodyParameters,
            // });

            // if (response.data.status === true) {
            //     setIsLoading(false);
            //     setAlertShow(false);

            //     navigate(`/${role + "/programs"}`);
            // } else {
            //     setIsLoading(false);
            //     setAlertShow(true);
            //     setJsonMessage(response.data.message);
            // }
        } catch (error) {
            setIsLoading(false);
            setAlertShow(true);
            setJsonMessage(
                error.response ? error.response.data.message : error.message
            );
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prev) => ({ ...prev, [name]: value }));

        if (name === "name") {
            if (!value) {
                setErrors((prev) => ({
                    ...prev,
                    name: "Program name is required.",
                }));
            } else {
                setErrors((prev) => ({ ...prev, name: "" }));
            }
        }
    };

    return (
        <>
            <div className="container-fluid">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <Components.PageHeader headingText="Create New Program" buttonText="Programs List" buttonIcon="list" buttonLink={`/${role}/programs`} />

                        <div className="nk-block">
                            <div className="card shadow-sm card-stretch">
                                <div className="card-inner">
                                    {alertShow === true && (
                                        <Components.Alert
                                            customClass="col-md-6"
                                            type="danger"
                                            icon="ni-cross-circle"
                                            message={jsonMessage}
                                            onClose={() => setAlertShow(false)}
                                        />
                                    )}

                                    <div className="row">
                                        <div className="col-md-4 col-12 mb-3">
                                            <Components.UnifiedInputField
                                                label="Name"
                                                type="text"
                                                name="name"
                                                value={formFields.name}
                                                onChange={handleChange}
                                                error={errors.name}
                                                placeholder="Enter Program Name"
                                            />
                                        </div>
                                    </div>

                                    <hr />
                                    <Components.Button
                                        isLoading={isLoading}
                                        onClick={() => navigate(-1)}
                                        label="Cancel"
                                        className="btn btn_danger"
                                    />

                                    <Components.Button
                                        isLoading={isLoading}
                                        onClick={onHandleSubmit}
                                        label="Submit"
                                        className="btn btn_accent ml-1"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateProgram;
