import React, { useEffect, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link } from "react-router-dom";

const UnifiedInputField = ({
    inputType = "input",
    label,
    type = "text",
    name,
    value,
    onChange,
    error,
    placeholder,
    togglePassword,
    passwordType,
    required,
    options = [],
    multiple = false,
    className,
    country,
    enableSearch,
    accept = null,
    checked
}) => {
    const selectRef = useRef(null);

    useEffect(() => {
        if (inputType === "select") {
            window.$(".chosen-select").chosen();
            window.$(selectRef.current).trigger("chosen:updated");
        }
    }, [options, inputType]);

    const renderInputField = () => {
        switch (inputType) {
            case "input":
                return (
                    <>
                        {type === "password" && (
                            <Link
                                to="#"
                                className="form-icon form-icon-right passcode-switch"
                                data-target={name}
                                onClick={togglePassword}
                            >
                                {passwordType === "password" ? (
                                    <em className="passcode-icon icon-show icon ni ni-eye-off" />
                                ) : (
                                    <em className="passcode-icon icon-show icon ni ni-eye" />
                                )}
                            </Link>
                        )}
                        <input
                            type={type === "password" ? passwordType : type}
                            className="form-control form-control-lg"
                            id={name}
                            name={name}
                            value={value}
                            onChange={onChange}
                            placeholder={placeholder}
                        />
                    </>
                );
            case "radio":
                return (
                    options &&
                    options.map((option, index) => (
                        <div className="custom-control custom-radio mr-2" key={index}>
                            <input
                                type="radio"
                                className="custom-control-input"
                                name={name}
                                id={`${name}_${index}`}
                                value={option.value}
                                onChange={onChange}
                                checked={String(checked) === String(option.value)}
                            />
                            <label
                                className="custom-control-label"
                                htmlFor={`${name}_${index}`}
                            >
                                {option.name}
                            </label>
                        </div>
                    ))
                );
            case "file":
                return (
                    <input
                        type="file"
                        className="form-control form-control-lg"
                        id={name}
                        name={name}
                        onChange={onChange}
                        placeholder={placeholder}
                        accept={accept}
                    />
                );
            case "select":
                return (
                    <select
                        className="chosen-select"
                        ref={selectRef}
                        name={name}
                        value={value || ""}
                        onChange={onChange}
                        multiple={multiple}
                    >
                        <option value={""} style={{ display: "none" }} disabled>
                            {placeholder}
                        </option>
                        {options.map((item, index) => (
                            <option key={index} value={item.value}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                );
            case "phone":
                return (
                    <PhoneInput
                        type={type}
                        className={className}
                        id={name}
                        name={name}
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        country={country}
                        enableSearch={enableSearch}
                    />
                );
            case "ckeditor":
                return (
                    <CKEditor
                        editor={ClassicEditor}
                        data={value}
                        placeholder={placeholder}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            if (onChange) {
                                onChange({ target: { name, value: data } });
                            }
                        }}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="form-group">
            <div className="form-label-group">
                <label className="form-label d-flex">
                    <span>{label}:</span>
                    {required && <h6 className="text-danger fw-bold">*</h6>}
                </label>
            </div>
            <div className="form-control-wrap">{renderInputField()}</div>
            {error && <small className="text-danger">{error}</small>}
        </div>
    );
};

export default UnifiedInputField;
