import React from "react";
import * as Components from '..';
import { RiCloseCircleFill } from "react-icons/ri";

const MultiPurposeModal = ({ show, onModalClose, icon, heading, text, subText, dangerButtontext, accentButtontext }) => {
    if (!show) return null;

    return (
        <div
            className="modal fade show"
            style={{
                display: "block",
                paddingLeft: 0,
                backgroundColor: "rgba(0,0,0,0.7)",
            }}
        >
            <div className="modal-dialog">
                <div className="modal-content" style={{ borderRadius: '10px' }}>
                    <RiCloseCircleFill onClick={onModalClose} className="modalCloseIcon" />
                    <div className="modal-body text-center">
                        <div className="nk-modal">
                            {icon}
                            <h4 className="nk-modal-title">{heading}</h4>
                            <div className="nk-modal-text">
                                <div className="caption-text">{text}</div>
                                <span className="sub-text-sm">{subText}</span>
                            </div>

                            <div className="nk-modal-action">
                                <Components.Button
                                    onClick={onModalClose}
                                    label={dangerButtontext}
                                    className="btn btn_danger"
                                />

                                <Components.Button
                                    // isLoading={isLoading}
                                    onClick={onModalClose}
                                    label={accentButtontext}
                                    className="btn btn_accent ml-1"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MultiPurposeModal;
