export const isAuthenticated = () => {
    const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
    return !!loginUserInfo;
};

export const getUserRole = () => {
    const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
    if (!loginUserInfo) return null;

    const role =
        loginUserInfo.role === "Super Admin"
            ? "super_admin"
            : loginUserInfo.role === "Admin"
                ? "admin"
                : null;
    return role;
};

export const getUserData = () => {
    return JSON.parse(localStorage.getItem("loginUserInfo"));
  };

export const login = (data) => {
    localStorage.setItem("loginUserInfo", JSON.stringify(data));
};

export const logout = () => {
    localStorage.removeItem("loginUserInfo");
};
