import React, { useState } from "react";
import * as Components from "../../Components";
import DummyImage from "../../Assets/backend/images/dummey_profile.jpeg"
import Data from "../../Data/index.json"
import { RiCloseCircleFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext";

const StudentForm = (props) => {
    const [fields, setFields] = useState([{
        full_name: '',
        email_address: '',
        contact_no: '',
        password: '',
        date_of_birth: '',
        gender: '',
        image: '',
        image_preview: DummyImage,
        address: '',

        admission_date: '',
        session: '',
        program: '',
        class: '',
        section: '',
        roll_no: '',

        tuition_fee: '',
        stationary_fee: '',
        admission_fee: '',
        annual_fee: '',
        fine: '',
        category: '',
        optional_subject: '',
        fee_status: '',

        favourite_food: '',
        favourite_vegetables: '',
        favourite_colors: '',
        favourite_drink: '',
        favourite_games: '',
        favourite_toys: '',
        languages: '',
        height: '',
        weight: '',
        religion: '',
        caste: '',
        blood_group: '',
        learning_power: '',
        pencil_grip: '',
        attachment_with: '',

    }]);
    const [password_type, setPasswordType] = useState("password");
    const [errors, setErrors] = useState([{}]);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const { role } = useAuth();

    const togglePassword = () => {
        setPasswordType(password_type === "password" ? "text" : "password");
    };

    const validateField = (name, value) => {
        let error = '';
        const full_name_pattern = /^[A-Za-z\s]{3,50}$/;
        const email_address_pattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
        const password_pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)\S{8,15}$/;

        switch (name) {
            case 'full_name':
                if (!value) {
                    error = "Full name is required.";
                } else if (!full_name_pattern.test(value)) {
                    error = "Full name must be between 3 to 50 characters and contain only alphabets.";
                }
                break;
            case 'email_address':
                if (!value) {
                    error = "Email address is required.";
                } else if (!email_address_pattern.test(value)) {
                    error = "Invalid email address. Only letters (a-z), numbers (0-9) and period (.) are allowed!";
                }
                break;
            case 'contact_no':
                if (!value) {
                    error = "Invalid contact number. Please ensure it includes the country code and follows the correct format.";
                }
                break;
            case 'password':
                if (!value) {
                    error = "Password is required.";
                } else if (!password_pattern.test(value)) {
                    error = "Password must contain at least one uppercase letter, one lowercase letter, one number, and be between 8 to 15 characters long.";
                }
                break;
            case 'date_of_birth':
                if (!value) {
                    error = "Date of birth is required.";
                }
                break;
            default:
                break;
        }

        return error;
    };

    const validateFields = () => {
        const newErrors = fields.map((studentFields) => {
            const studentErrors = {};

            Object.keys(studentFields).forEach((key) => {
                const error = validateField(key, studentFields[key]);
                if (error) {
                    studentErrors[key] = error;
                }
            });

            return studentErrors;
        });

        setErrors(newErrors);
        return newErrors.every((error) => Object.keys(error).length === 0);
    };

    const handleChange = (index, e) => {
        const { name, value, type, files } = e.target;

        setFields((prev) => {
            const newFields = [...prev];
            if (type === 'file') {
                const file = files[0];
                newFields[index] = { ...newFields[index], [name]: file };

                if (file) {
                    newFields[index].image_preview = URL.createObjectURL(file);
                } else {
                    newFields[index].image_preview = DummyImage;
                }
            } else {
                newFields[index] = { ...newFields[index], [name]: value };
            }

            return newFields;
        });

        const error = validateField(name, type === 'file' ? files[0] : value);
        setErrors((prev) => {
            const newErrors = [...prev];
            newErrors[index] = { ...newErrors[index], [name]: error };
            return newErrors;
        });
    };

    const handleContactNoChange = (index, value, country) => {
        const dialCodeLength = country.dialCode.length;
        const formatLength = country.format.replace(/ /g, "").length;
        const isValidLength = value.length + dialCodeLength === formatLength;

        setFields((prev) => {
            const newFields = [...prev];
            newFields[index] = { ...newFields[index], contact_no: value };
            return newFields;
        });

        const error = isValidLength ? null : "Invalid contact number. Please ensure it includes the country code and follows the correct format.";

        setErrors((prev) => {
            const newErrors = [...prev];
            newErrors[index] = { ...newErrors[index], contact_no: error };
            return newErrors;
        });
    };

    const addMoreStudent = () => {
        setFields([...fields, {
            full_name: '',
            email_address: '',
            contact_no: '',
            password: '',
            date_of_birth: '',
            gender: '',
            image: '',
            image_preview: DummyImage,
            address: '',
        }]);
        setErrors([...errors, {}]);
    };

    const removeStudent = (index) => {
        const newStudents = fields.filter((_, i) => i !== index);
        const newErrors = errors.filter((_, i) => i !== index);
        setFields(newStudents);
        setErrors(newErrors);
    };

    const onHandleSubmit = async () => {
        setIsLoading(true);
        if (!validateFields()) {
            setIsLoading(false);
            return;
        }

        try {
            setTimeout(() => {
                setIsLoading(false);
                navigate(`/${role + "/enrollments/parents"}`);
            }, 3000);
        } catch (error) {
            setIsLoading(false);
        }
    };

    return (
        <>
            {fields.slice().reverse().map((item, index) => {
                const original_index = fields.length - 1 - index;
                return (
                    <div key={index} className="student_wrapper mb-2 pt-3 px-4">
                        <span className="count"> {original_index < 9 ? "0" + (original_index + 1) : original_index + 1}</span>

                        {original_index > 0 && <RiCloseCircleFill onClick={() => removeStudent(original_index)} className="close_icon" />}

                        <div className="row mb-2">
                            <div className="col-12">
                                <h5 className=" bg-dark p-2 rounded text-light">Personal Information:</h5>
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    label="Full Name"
                                    type="text"
                                    name="full_name"
                                    value={item.full_name}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.full_name}
                                    placeholder="Enter Full Name"
                                    required={true}
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    label="Email Address"
                                    type="email"
                                    name="email_address"
                                    value={item.email_address}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.email_address}
                                    placeholder="Enter Email Address"
                                    required={true}
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="phone"
                                    label="Contact Number"
                                    type="text"
                                    name="contact_no"
                                    value={item.contact_no}
                                    onChange={(value, country) => handleContactNoChange(original_index, value, country)}
                                    placeholder="Enter Contact Number"
                                    error={errors[original_index]?.contact_no}
                                    className="phone_input"
                                    country={"pk"}
                                    enableSearch={true}
                                    required={true}
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    label="Password"
                                    type="password"
                                    name="password"
                                    value={item.password}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.password}
                                    placeholder="Enter Password"
                                    togglePassword={togglePassword}
                                    password_type={password_type}
                                    required={true}
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    label="Date of Birth"
                                    type="date"
                                    name="date_of_birth"
                                    value={item.date_of_birth}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.date_of_birth}
                                    placeholder="Enter Date of Birth"
                                    required={true}
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="select"
                                    label="Gender"
                                    type="select"
                                    name="gender"
                                    value={item.gender}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.gender}
                                    placeholder="Select Gender"
                                    required={false}
                                    options={Data.gender}
                                />
                            </div>

                            <div className="col-md-11 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="file"
                                    label="Image"
                                    type="file"
                                    name="image"
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.image}
                                    placeholder="Upload Image"
                                    required={false}
                                    accept="image/*"
                                />
                            </div>

                            <div className="col-12 col-md-1 mt-4 text-center">
                                <img
                                    src={item.image_preview}
                                    alt={`Student_Image_${original_index}`}
                                    className="rounded-circle"
                                    height="80"
                                    width="80"
                                />
                            </div>

                            <div className="col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="ckeditor"
                                    label="Address"
                                    type="textarea"
                                    name="address"
                                    value={item.address}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.address}
                                    placeholder="Enter Address"
                                />
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-12">
                                <h5 className="bg-dark p-2 rounded text-light">Admission Details:</h5>
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="input"
                                    label="Admission Date"
                                    type="date"
                                    name="admission_date"
                                    value={item.admission_date}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.admission_date}
                                    placeholder="Enter Admission Date"
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="select"
                                    label="Session"
                                    name="session"
                                    value={item.session}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.session}
                                    placeholder="Select Session"
                                    required={true}
                                    options={Data.sessions}
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="select"
                                    label="Program"
                                    name="program"
                                    value={item.program}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.program}
                                    placeholder="Select Program"
                                    required={true}
                                    options={Data.programs}
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="select"
                                    label="Class"
                                    name="class"
                                    value={item.class}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.class}
                                    placeholder="Select Class"
                                    required={true}
                                    options={Data.classes}
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="select"
                                    label="Section"
                                    name="section"
                                    value={item.section}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.section}
                                    placeholder="Select Section"
                                    required={true}
                                    options={Data.sections}
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="select"
                                    label="Roll No"
                                    name="roll_no"
                                    value={item.roll_no}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.roll_no}
                                    placeholder="Select Roll No"
                                    required={true}
                                    options={Data.sessions}
                                />
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-12">
                                <h5 className="bg-dark p-2 rounded text-light">Academic Information:</h5>
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="input"
                                    label="Tuition Fee"
                                    type="number"
                                    name="tuition_fee"
                                    value={item.tuition_fee}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.tuition_fee}
                                    placeholder="Enter Tuition Fee"
                                    required={true}
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="input"
                                    label="Stationary Fee"
                                    type="number"
                                    name="stationary_fee"
                                    value={item.stationary_fee}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.stationary_fee}
                                    placeholder="Enter Stationary Fee"
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="input"
                                    label="Admission Fee"
                                    type="number"
                                    name="admission_fee"
                                    value={item.admission_fee}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.admission_fee}
                                    placeholder="Enter Admission Fee"
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="input"
                                    label="Annual Fee"
                                    type="number"
                                    name="annual_fee"
                                    value={item.annual_fee}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.annual_fee}
                                    placeholder="Enter Annual Fee"
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="input"
                                    label="Fine"
                                    type="number"
                                    name="fine"
                                    value={item.fine}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.fine}
                                    placeholder="Enter Fine"
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="select"
                                    label="Category"
                                    name="category"
                                    value={item.category}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.category}
                                    placeholder="Select Category"
                                    options={[
                                        { label: "Category One", value: 1 },
                                        { label: "Category Two", value: 2 },
                                    ]}
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="select"
                                    label="Optional Subject"
                                    name="optional_subject"
                                    value={item.optional_subject}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.optional_subject}
                                    placeholder="Select Optional Subject"
                                    options={[
                                        { label: "Optional Subject One", value: 1 },
                                        { label: "Optional Subject Two", value: 2 },
                                    ]}
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="select"
                                    label="Fee Status"
                                    name="fee_status"
                                    value={item.fee_status}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.fee_status}
                                    placeholder="Select Fee Status"
                                    options={[
                                        { label: "Free", value: 1 },
                                        { label: "Pay", value: 2 },
                                    ]}
                                />
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-12">
                                <h5 className=" bg-dark p-2 rounded text-light">Preferences:</h5>
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="input"
                                    label="Favourite Food"
                                    type="text"
                                    name="favourite_food"
                                    value={item.favourite_food}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.favourite_food}
                                    placeholder="Enter Favourite Food"
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="input"
                                    label="Favourite Vegetables"
                                    type="text"
                                    name="favourite_vegetables"
                                    value={item.favourite_vegetables}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.favourite_vegetables}
                                    placeholder="Enter Favourite Vegetables"
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="input"
                                    label="Favourite Colors"
                                    type="text"
                                    name="favourite_colors"
                                    value={item.favourite_colors}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.favourite_colors}
                                    placeholder="Enter Favourite Colors"
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="input"
                                    label="Favourite Drink"
                                    type="text"
                                    name="favourite_drink"
                                    value={item.favourite_drink}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.favourite_drink}
                                    placeholder="Enter Favourite Drink"
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="input"
                                    label="Favourite Games"
                                    type="text"
                                    name="favourite_games"
                                    value={item.favourite_games}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.favourite_games}
                                    placeholder="Enter Favourite Games"
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="input"
                                    label="Favourite Toys"
                                    type="text"
                                    name="favourite_toys"
                                    value={item.favourite_toys}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.favourite_toys}
                                    placeholder="Enter Favourite Toys"
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="input"
                                    label="Languages"
                                    type="text"
                                    name="languages"
                                    value={item.languages}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.languages}
                                    placeholder="Enter Languages"
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="input"
                                    label="Height"
                                    type="text"
                                    name="height"
                                    value={item.height}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.height}
                                    placeholder="Enter Height"
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="input"
                                    label="Weight"
                                    type="text"
                                    name="weight"
                                    value={item.weight}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.weight}
                                    placeholder="Enter Weight"
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="input"
                                    label="Religion"
                                    type="text"
                                    name="religion"
                                    value={item.religion}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.religion}
                                    placeholder="Enter Religion"
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="input"
                                    label="Caste"
                                    type="text"
                                    name="caste"
                                    value={item.caste}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.caste}
                                    placeholder="Enter Caste"
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="select"
                                    label="Blood Group"
                                    name="blood_group"
                                    value={item.blood_group}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.blood_group}
                                    placeholder="Select Blood Group"
                                    options={[
                                        { label: "Excellent", value: 1 },
                                        { label: "Good", value: 2 },
                                        { label: "Better", value: 3 },
                                    ]}
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="select"
                                    label="Learning Power"
                                    name="learning_power"
                                    value={item.learning_power}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.learning_power}
                                    placeholder="Select Learning Power"
                                    options={[
                                        { label: "Excellent", value: 1 },
                                        { label: "Good", value: 2 },
                                        { label: "Better", value: 3 },
                                    ]}
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="select"
                                    label="Pencil Grip"
                                    name="pencil_grip"
                                    value={item.pencil_grip}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.pencil_grip}
                                    placeholder="Select Pencil Grip"
                                    options={[
                                        { label: "Yes", value: 1 },
                                        { label: "No", value: 2 },
                                    ]}
                                />
                            </div>

                            <div className="col-md-4 col-12 mt-3">
                                <Components.UnifiedInputField
                                    inputType="select"
                                    label="Attachment With"
                                    name="attachment_with"
                                    value={item.attachment_with}
                                    onChange={(e) => handleChange(original_index, e)}
                                    error={errors[original_index]?.attachment_with}
                                    placeholder="Select Attachment With"
                                    options={[
                                        { label: "Father", value: 1 },
                                        { label: "Mother", value: 2 },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                );
            })}

            <hr />
            <Components.Button
                onClick={props.goToPreviousStep}
                label="Back"
                className="btn btn_danger"
            />

            <Components.Button
                onClick={addMoreStudent}
                label="Add More Student"
                className="btn btn_secondary"
            />

            <Components.Button
                isLoading={isLoading}
                onClick={onHandleSubmit}
                label="Submit"
                className="btn btn_accent"
            />
        </>
    );
};

export default StudentForm;
