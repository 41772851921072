import React from "react";
import { Spinner } from "react-bootstrap";

const Button = ({
    isLoading,
    onClick,
    label,
    id,
    className,
    icon,
    buttonType = "button",
    iconClassName,
    hidden,
}) => {
    return (
        <button
            type={buttonType}
            id={id}
            className={className}
            onClick={onClick}
            disabled={isLoading}
            hidden={hidden}
        >
            {isLoading ? (
                <>
                    <Spinner animation="border" variant="light" size="sm" />
                    <span className="ml-2">Loading...</span>
                </>
            ) : (
                <>
                    {icon && <em className={`icon ni ni-${icon} ${iconClassName}`} />}
                    <span className={icon ? 'd-none d-md-inline-flex' : ''}>{label}</span>
                </>
            )}
        </button>
    );
};

export default Button;
