import React from "react";

const Footer = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="nk-footer-wrap justify-content-center text-center">
          <div className="nk-footer-copyright">
            © 2024 Hazri Xpert. Represented By{" "}
            <a
              href="https://xpertsgroup.net"
              target="_blank"
              rel="noreferrer"
              className="footer_website_link"
            >
              XpertSpot Systems
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
