import React from "react";

const Alert = ({ type, icon, message, onClose, customClass }) => {
    return (
        <div className={`alert alert-fill alert-${type} alert-dismissible alert-icon mb-2 ${customClass}`}>
            <em className={`icon ni ${icon}`}></em>
            <strong>Error!</strong> {message}
            <button className="close" onClick={onClose}></button>
        </div>
    );
};

export default Alert;
